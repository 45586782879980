import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useDeepCompareEffect} from 'react-use';
import {FormInput} from '~/shared/components/inputs/form-input';
import type {UpsertJobFormSchema} from '~/shared/schemas/job';
import {getDefaultUpsertJobFormValues, upsertJobSchema} from '~/shared/schemas/job';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, LoadingSpinner, Overlay, RichText, Tabs, TextButton} from '@job-ish/ui/components';
import {
	IconCalendarTime,
	IconCircleCheck,
	IconFileDatabase,
	IconListDetails,
	IconNote,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import debounce from 'lodash.debounce';

import type {Company, Job} from '@job-ish/database/types';
import type {FormEventHandler} from 'react';
import type {SubmitHandler} from 'react-hook-form';
import {UpsertJobFormDates} from './dates';
import {UpsertJobFormDetails} from './details';
import {UpsertJobFormResources} from './resources';

dayjs.extend(utc);

type UpsertJobFormProps = {
	job?: Job;
	onSubmit: SubmitHandler<UpsertJobFormSchema>;
	initialValues?: Omit<Partial<Job>, 'company'> & {
		company?: Partial<Company>;
	};
	defaultTab?: string;
};

export const UpsertJobForm = ({job, onSubmit, initialValues, defaultTab = 'details'}: UpsertJobFormProps) => {
	const [payType, setPayType] = useState<'salary' | 'hourly'>(
		job?.min_hourly_rate || job?.max_hourly_rate ? 'hourly' : 'salary',
	);

	const [mounted, setMounted] = useState(false);
	const [creating, setCreating] = useState(false);
	const [saving, setSaving] = useState(false);
	const [tabValue, setTabValue] = useState(defaultTab);
	const ref = useRef<HTMLFormElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop = 0;
		}
	}, [tabValue]);

	const {control, handleSubmit, reset, setValue, watch} = useForm<UpsertJobFormSchema>({
		resolver: zodResolver(upsertJobSchema),
		defaultValues: getDefaultUpsertJobFormValues(job || initialValues),
		mode: job ? 'all' : undefined,
		reValidateMode: job ? 'onChange' : undefined,
	});

	const formValues = useWatch({control});

	const debouncedSubmit = debounce(() => {
		if (!job) return;
		if (!mounted) {
			setMounted(true);
			return;
		}

		handleSubmit(data => {
			setSaving(true);
			onSubmit(data);
		})();
	}, 750);

	useDeepCompareEffect(() => {
		debouncedSubmit();
	}, [formValues]);

	useEffect(() => {
		if (job) {
			setCreating(false);
			setTimeout(() => {
				setSaving(false);
			}, 150);
		}
	}, [job]);

	const submitForm: FormEventHandler<HTMLFormElement> = useCallback(
		async event => {
			event.preventDefault();
			event.stopPropagation();
			if (!job) setCreating(true);
			await handleSubmit(onSubmit, errors => {
				setCreating(false);
				if (errors.title || errors.status || errors.remote) setTabValue('details');
				else if (errors.url) setTabValue('listing');
				else setTabValue('details');
			})();
		},
		[handleSubmit, job, onSubmit],
	);

	const resetForm: FormEventHandler<HTMLFormElement> = useCallback(
		event => {
			event.preventDefault();
			event.stopPropagation();
			setPayType(job?.min_hourly_rate || job?.max_hourly_rate ? 'hourly' : 'salary');
			reset();
		},
		[job?.max_hourly_rate, job?.min_hourly_rate, reset],
	);

	return (
		<form
			className="relative flex grow flex-col overflow-hidden"
			onReset={resetForm}
			onSubmit={submitForm}
			ref={ref}
		>
			{creating && <Overlay loading />}
			<Tabs
				className="flex grow flex-col overflow-y-hidden"
				defaultValue="details"
				onValueChange={setTabValue}
				value={tabValue}
			>
				<Tabs.List className="flex-shrink-0" flush>
					<Tabs.Trigger iconLeft={IconListDetails} value="details">
						Details
					</Tabs.Trigger>
					<Tabs.Trigger iconLeft={IconFileDatabase} value="resources">
						Resources
					</Tabs.Trigger>
					<Tabs.Trigger iconLeft={IconNote} value="notes">
						Notes
					</Tabs.Trigger>
					<Tabs.Trigger iconLeft={IconCalendarTime} value="dates">
						Dates
					</Tabs.Trigger>
				</Tabs.List>
				<div className="grow overflow-y-auto" ref={scrollRef}>
					<Tabs.Content value="details">
						<UpsertJobFormDetails
							control={control}
							payType={payType}
							setPayType={setPayType}
							setValue={setValue}
							watch={watch}
						/>
					</Tabs.Content>

					<Tabs.Content value="resources">
						<UpsertJobFormResources control={control} id={job?.id} setValue={setValue} watch={watch} />
					</Tabs.Content>

					<Tabs.Content value="notes">
						<div className="flex h-full flex-col gap-3">
							<FormInput
								className="mb-1 h-full"
								control={control}
								label="Notes"
								name="notes"
								render={field => (
									<RichText
										className="min-h-[24rem]"
										content={field.value}
										onContentChange={value =>
											setValue('notes', value, {shouldDirty: true, shouldValidate: true})
										}
										placeholder="Enter notes"
									/>
								)}
							/>

							<FormInput
								className="mb-1 h-full"
								control={control}
								label="Job Description"
								name="job_description"
								render={field => (
									<RichText
										className="min-h-[24rem]"
										content={field.value}
										onContentChange={value =>
											setValue('job_description', value, {shouldDirty: true, shouldValidate: true})
										}
										placeholder="Enter job description"
									/>
								)}
							/>
						</div>
					</Tabs.Content>

					<Tabs.Content value="dates">
						<UpsertJobFormDates control={control} job={job} setValue={setValue} />
					</Tabs.Content>
				</div>
			</Tabs>

			{job ? (
				<div className="ml-auto flex items-center gap-1 pt-2.5 text-sm">
					{saving ? (
						<>
							<span>Saving...</span>
							<LoadingSpinner size="sm" />
						</>
					) : (
						<>
							<span>All changes saved</span>
							<IconCircleCheck className="h-4 w-4" />
						</>
					)}
				</div>
			) : (
				<div className="mt-auto flex h-16 shrink-0 items-center justify-end gap-3 px-2">
					<TextButton className="-mb-2.5" type="reset">
						Reset
					</TextButton>
					<Button className="-mb-2.5" color="primary" type="submit">
						Add to Dashboard
					</Button>
				</div>
			)}
		</form>
	);
};
