import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const tabsStyles = cva('group w-full overflow-y-hidden', {
	variants: {orientation: {horizontal: 'flex flex-col', vertical: 'flex flex-row'}},
	defaultVariants: {orientation: 'horizontal'},
});

export const tabsListStyles = cva(
	[
		'relative flex max-h-full max-w-full overflow-x-auto bg-mauve2 data-[orientation=horizontal]:rounded-t-md data-[orientation=vertical]:rounded-l-md',
		'data-[orientation=horizontal]:flex-row data-[orientation=horizontal]:items-start',
		'data-[orientation=vertical]:flex-col data-[orientation=vertical]:items-center',
		'data-[orientation=vertical]:overflow-x-hidden p-1.5 min-h-fit shrink-0 gap-1.5',
	],
	{
		variants: {
			flush: {
				true: 'data-[orientation=vertical]:h-full data-[orientation=horizontal]:w-full',
				false: 'h-fit w-fit',
			},
		},
		defaultVariants: {flush: false},
	},
);

export const tabsTriggerStyles = cva([
	'relative flex grow justify-center shrink-0',
	'group-data-[orientation=vertical]:h-10 group-data-[orientation=vertical]:min-w-full group-data-[orientation=vertical]:flex-row-reverse',
	'group-data-[orientation=horizontal]:flex-col',
	'group-data-[orientation=horizontal]:rounded-t-md group-data-[orientation=horizontal]:[&>*:first-child]:rounded-t-md',
	'group-data-[orientation=vertical]:rounded-l-md group-data-[orientation=vertical]:[&>*:first-child]:rounded-l-md',
]);

export const tabIndicatorStyles = cva([
	'absolute [&&]:z-0 h-full w-full bg-mauve5',
	'group-data-[orientation=horizontal]:rounded-t-md group-data-[orientation=horizontal]:[&>*:first-child]:rounded-t-md',
	'group-data-[orientation=vertical]:rounded-l-md group-data-[orientation=vertical]:[&>*:first-child]:rounded-l-md',
	'group-data-[orientation=horizontal]:border-b-4 group-data-[orientation=vertical]:border-l-4 group-data-[orientation=horizontal]:border-b-violet9 group-data-[orientation=vertical]:border-l-violet9',
]);

export const tabsTriggerButtonStyles = cva([
	'peer relative flex-grow whitespace-nowrap px-4 py-1.5 transition-all group ease-in-out hover:bg-mauve4 hover:text-mauve12 active:text-mauve12 bg-mauve3',
	'focus-visible:bg-mauve5 [&]:focus-visible:outline-none rounded-none [&>div_*]:z-10',
	'data-[state=active]:text-violet12 data-[state=inactive]:text-mauve11',
]);

export const tabsContentStyles = cva([
	'px-2 text-mauve11 focus-visible:outline-none data-[state=inactive]:hidden',
	'group-data-[orientation=horizontal]:pt-6',
	'group-data-[orientation=vertical]:pl-6',
]);

export type TabsListVariantProps = VariantProps<typeof tabsListStyles>;
export type TabsTriggerVariantProps = VariantProps<typeof tabsTriggerStyles>;
