import {forwardRef, Fragment, useRef} from 'react';
import {useCalendarState} from 'react-stately';
import {createCalendar, getLocalTimeZone, getWeeksInMonth, isSameDay, isToday} from '@internationalized/date';
import {useButton} from '@react-aria/button';
import {useCalendar, useCalendarCell, useCalendarGrid} from '@react-aria/calendar';
import {useLocale} from '@react-aria/i18n';
import {mergeProps} from '@react-aria/utils';
import {IconChevronLeft, IconChevronRight} from '@tabler/icons-react';
import assign from 'lodash.assign';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {CalendarDate, CalendarDateTime} from '@internationalized/date';
import type {AriaCalendarGridProps, AriaCalendarProps, DateValue} from '@react-aria/calendar';
import type {ReactNode} from 'react';
import type {CalendarState, RangeCalendarState} from 'react-stately';
import type {SetOptional} from 'type-fest';
import Card from '../card';
import IconButton from '../icon-button';
import {calendarCellStyles} from './styles';
import type {BadgeProps} from '../badge';
import type {CardProps} from '../card';

export type CalendarEvent = {
	[key: string]: unknown;
	date: CalendarDateTime;
	title: string;
	color: BadgeProps['color'];
};

type CalendarCellProps = {
	state: CalendarState | SetOptional<RangeCalendarState, 'highlightedRange'>;
	compact?: boolean;
	highlightSelected?: boolean;
	date: CalendarDate;
	eventRender?: (event: CalendarEvent) => ReactNode;
	events?: CalendarEvent[];
	dateRender?: (date: CalendarDate) => ReactNode;
	onSelect?: () => void;
};
const CalendarCell = ({
	state,
	compact,
	date,
	eventRender,
	events,
	dateRender,
	onSelect,
	highlightSelected,
}: CalendarCellProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const {
		cellProps,
		buttonProps,
		isSelected,
		isOutsideVisibleRange,
		isDisabled,
		isUnavailable,
		formattedDate,
	} = useCalendarCell({date}, state as CalendarState | RangeCalendarState, ref);

	const {buttonProps: cellButtonProps} = useButton({onPress: onSelect}, ref);

	const isSelectionStart =
		'highlightedRange' in state && state.highlightedRange
			? isSameDay(date, state.highlightedRange.start)
			: isSelected;
	const isSelectionEnd =
		'highlightedRange' in state && state.highlightedRange
			? isSameDay(date, state.highlightedRange.end)
			: isSelected;

	return isOutsideVisibleRange && compact ? null : (
		<td {...cellProps} className="relative h-full">
			<div
				{...mergeProps(buttonProps, cellButtonProps)}
				className={calendarCellStyles({
					selected: highlightSelected
						? 'highlightedRange' in state
							? !!(isSelectionStart || isSelectionEnd)
							: isSelected
						: false,
					withinSelectedRange:
						'highlightedRange' in state ? !isSelectionStart && !isSelectionEnd && isSelected : false,
					selectedRangeLimit: isSelectionStart || isSelectionEnd,
					disabled: isDisabled,
					unavailable: isUnavailable,
					hidden: isOutsideVisibleRange,
					today: isToday(date, getLocalTimeZone()),
					compact,
				})}
				hidden={isOutsideVisibleRange}
				ref={ref}
				role="button"
				tabIndex={0}
			>
				<div className={compact ? 'h-1/6' : 'my-auto'}>{dateRender?.(date) ?? formattedDate}</div>
			</div>
			{events && events.length > 0 && (
				<div className="max-h-5/6 absolute left-0 top-0 flex h-fit w-full flex-col overflow-y-auto rounded-sm p-0.5">
					{events.map((event, index) => (
						<Fragment key={`${event.title}-${index}-${event.date}`}>{eventRender?.(event)}</Fragment>
					))}
				</div>
			)}
		</td>
	);
};

type CalendarGridProps = {
	state: CalendarState | RangeCalendarState;
	compact?: boolean;
	highlightSelected?: boolean;
	eventRender?: (event: CalendarEvent) => ReactNode;
	events?: CalendarEvent[];
	dateRender?: (date: CalendarDate) => ReactNode;
	onDateSelect?: (date: CalendarDate) => void;
} & AriaCalendarGridProps;
const CalendarGrid = ({
	state,
	compact,
	highlightSelected,
	events,
	eventRender,
	dateRender,
	onDateSelect,
	...props
}: CalendarGridProps) => {
	const {locale} = useLocale();
	const {gridProps, headerProps, weekDays} = useCalendarGrid(props, state);
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

	return (
		<div className="mx-auto flex w-full flex-grow flex-col overflow-x-auto">
			<table {...gridProps} className="h-[1px] w-full flex-grow overflow-x-auto">
				<thead {...headerProps} className="h-8">
					<tr>
						{weekDays.map((day, index) => (
							<th className="text-mauve12" key={index}>
								{day}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="h-[95%]">
					{[...Array.from({length: weeksInMonth}).keys()].map(weekIndex => (
						<tr key={weekIndex}>
							{state
								.getDatesInWeek(weekIndex, state.visibleRange.start)
								.map((date, index) =>
									date ? (
										<CalendarCell
											compact={compact}
											date={date}
											dateRender={dateRender}
											eventRender={eventRender}
											events={events?.filter(event => isSameDay(event.date, date))}
											highlightSelected={highlightSelected}
											key={index}
											onSelect={() => onDateSelect?.(date)}
											state={state}
										/>
									) : (
										<td key={index} />
									),
								)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export type CalendarProps = AriaCalendarProps<DateValue> &
	Omit<CardProps, 'defaultValue' | 'onChange' | 'asChild'> & {
		footer?: ReactNode;
		compact?: boolean;
		highlightSelected?: boolean;
		events?: CalendarEvent[];
		eventRender?: (event: CalendarEvent) => ReactNode;
		dateRender?: (date: CalendarDate) => ReactNode;
		onDateSelect?: (date: CalendarDate) => void;
		weekdayStyle?: CalendarGridProps['weekdayStyle'];
	};

const Calendar = assign(
	forwardRef<HTMLDivElement, CalendarProps>(
		(
			{
				padding = 'sm',
				shadow,
				bordered,
				accent,
				accentPosition,
				enableMotion,
				motionOverrides,
				footer,
				highlightSelected = true,
				compact,
				events,
				eventRender,
				dateRender,
				onDateSelect,
				weekdayStyle,
				className,
				...props
			},
			forwardedRef,
		) => {
			const {locale} = useLocale();
			const state = useCalendarState({
				...props,
				locale,
				createCalendar,
				visibleDuration: compact ? {weeks: 1} : undefined,
			});
			const {calendarProps, prevButtonProps, nextButtonProps, title} = useCalendar(props, state);

			return (
				<Card
					{...(calendarProps as CardProps)}
					accent={accent}
					accentPosition={accentPosition}
					bordered={bordered}
					className={twMerge('w-fit', className)}
					enableMotion={enableMotion}
					motionOverrides={motionOverrides}
					padding={padding}
					ref={forwardedRef}
					shadow={shadow}
				>
					<Card.Header>
						<IconButton {...omit(prevButtonProps, 'isDisabled')} circular icon={IconChevronLeft} size="sm" />
						<Card.Title className="text-center">{title.replaceAll('\u{2009}', ' ')}</Card.Title>
						<IconButton {...omit(nextButtonProps, 'isDisabled')} circular icon={IconChevronRight} size="sm" />
					</Card.Header>
					<Card.Body className="flex h-full flex-col overflow-hidden">
						<CalendarGrid
							compact={compact}
							dateRender={dateRender}
							eventRender={eventRender}
							events={events}
							highlightSelected={highlightSelected}
							onDateSelect={onDateSelect}
							state={state}
							weekdayStyle={weekdayStyle}
						/>
					</Card.Body>
					{footer && <Card.Footer>{footer}</Card.Footer>}
				</Card>
			);
		},
	),
	{Cell: CalendarCell, Grid: CalendarGrid},
);

Calendar.displayName = 'Calendar';

export default Calendar;

export * from './styles';
