import {forwardRef, useEffect, useState} from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import {AnimatePresence} from 'framer-motion';
import {uniqBy} from 'lodash';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';
import {v4 as uuid} from 'uuid';

import type {ReactNode} from 'react';
import {useToastStore} from '../../hooks';
import Paper from '../paper';
import ProgressBar from '../progress-bar';
import {toastStyles, toastViewportStyles} from './styles';
import type {PaperVariantProps} from '../paper/styles';
import type {ToastVariantProps} from './styles';

export type ToastProviderProps = ToastPrimitive.ToastProviderProps;
const ToastProvider = ({children, ...props}: ToastProviderProps) => {
	const {toasts} = useToastStore();

	return (
		<ToastPrimitive.ToastProvider {...props}>
			{children}
			{uniqBy(Object.values(toasts), 'id').map(
				toast => toast && <Toast key={`${uuid()}-${toast.id}`} {...toast} />,
			)}
			<ToastPrimitive.Viewport className={toastViewportStyles()} />
		</ToastPrimitive.ToastProvider>
	);
};

export const useDurationElapsed = ({duration = 0, open, onDurationElapsed}: Partial<ToastProps>) => {
	const [durationElapsed, setDurationElapsed] = useState(0);
	const durationIncrement = duration / Math.round((duration * 0.85) / 100);

	useEffect(() => {
		if (duration && open && duration !== Number.POSITIVE_INFINITY) {
			const interval = setInterval(() => {
				if (durationElapsed < duration) {
					setDurationElapsed(previous => previous + durationIncrement);
				} else {
					clearInterval(interval);
				}
			}, 100);

			return () => clearInterval(interval);
		}
	}, [durationElapsed, durationIncrement, duration, open]);

	useEffect(() => {
		if (!open) setDurationElapsed(0);
	}, [open]);

	useEffect(() => {
		if (duration && durationElapsed >= duration && onDurationElapsed) {
			setTimeout(onDurationElapsed, 500);
		}
	}, [duration, durationElapsed, onDurationElapsed]);

	return Math.min(durationElapsed, duration);
};

export type ToastAction = ToastPrimitive.ToastActionProps;
const ToastAction = forwardRef<HTMLButtonElement, ToastAction>((props, forwardedRef) => (
	<ToastPrimitive.Action
		{...props}
		asChild
		className="h-fit w-fit self-center justify-self-end outline-none"
		ref={forwardedRef}
	/>
));

export type ToastProps = Omit<ToastPrimitive.ToastProps, 'title'> &
	ToastVariantProps &
	Omit<PaperVariantProps, 'padding' | 'shadow' | 'bordered' | 'enableMotion'> & {
		title: ReactNode;
		description: ReactNode;
		onDurationElapsed?: () => void;
	};
const Toast = assign(
	forwardRef<HTMLLIElement, ToastProps>(
		(
			{
				id,
				open,
				accent,
				accentPosition,
				title,
				description,
				onDurationElapsed,
				duration,
				className,
				draggable,
				children,
				...props
			},
			forwardedRef,
		) => {
			const durationElapsed = useDurationElapsed({...props, duration, onDurationElapsed, open});

			return (
				<AnimatePresence mode="wait">
					<ToastPrimitive.Root
						{...props}
						className={twMerge(toastStyles({draggable}), className)}
						duration={duration}
						id={id}
						key={id}
						open={open}
						ref={forwardedRef}
					>
						<Paper
							accent={accent}
							accentPosition={accentPosition}
							bordered
							className="bottom-0 right-0 h-fit min-h-[5rem] items-start"
							enableMotion
							motionOverrides={{
								style: {position: open ? 'relative' : 'absolute'},
								layout: true,
								transition: {type: 'spring', stiffness: 900, damping: 40, duration: 1},
							}}
							padding="md"
							shadow
						>
							<div className="flex h-fit flex-col gap-1">
								{title && (
									<ToastPrimitive.Title className="font-medium text-mauve12">{title}</ToastPrimitive.Title>
								)}
								{description && (
									<ToastPrimitive.Description className="text-pretty text-sm text-mauve11">
										{description}
									</ToastPrimitive.Description>
								)}
								{children && <div className="flex items-center justify-start gap-1">{children}</div>}
							</div>
							{duration && open && (
								<div className="absolute bottom-0 left-0 right-0">
									<ProgressBar
										className="rounded-bl-sm rounded-br-sm rounded-tl-none rounded-tr-none"
										color={accent ?? undefined}
										max={duration}
										size="sm"
										value={(durationElapsed / duration) * 100}
									/>
								</div>
							)}
						</Paper>
					</ToastPrimitive.Root>
				</AnimatePresence>
			);
		},
	),
	{Action: ToastAction, Provider: ToastProvider},
);

Toast.displayName = 'Toast';
Toast.Action.displayName = 'Toast.Action';

export default Toast;

export * from './styles';
