import type {Readable} from 'node:stream';

export const postData = async <T>({
	url,
	data,
	method = 'POST',
	next,
	cache,
}: {
	url: string | URL | Request;
	next?: NextFetchRequestConfig;
	cache?: RequestCache;
} & (
	| {data?: {[key: string]: unknown}; method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH'}
	| {data?: never; method: 'GET'}
)): Promise<T | null> => {
	const res: Response = await fetch(url, {
		method,
		headers: new Headers({
			'Content-Type': 'application/json',
			Cookie:
				typeof window === 'undefined'
					? await import('next/headers').then(async ({cookies}) => {
							const awaitedCookies = await cookies();
							return awaitedCookies.toString();
						})
					: document.cookie,
		}),
		credentials: 'same-origin',
		body: JSON.stringify(data),
		next,
		cache,
	});

	if (!res.ok) {
		return null;
	}

	return res.json();
};

export const getRawBody = async (readable: Readable) => {
	const chunks = [];
	for await (const chunk of readable) {
		chunks.push(typeof chunk === 'string' ? Buffer.from(chunk) : chunk);
	}
	return Buffer.concat(chunks);
};

export const fetchWithRetry = async <T>(
	fetchFn: (bypassCache: boolean) => Promise<T>,
	successCheckCallback: (result: T) => boolean,
	retries = 3,
	delay = 1000,
): Promise<T | null> => {
	for (let attempt = 0; attempt < retries; attempt++) {
		try {
			const result = await fetchFn(attempt > 0);

			if (successCheckCallback(result)) {
				return result;
			}
		} catch {
			if (attempt < retries - 1) {
				await new Promise(resolve => setTimeout(resolve, delay));
			}
		}
	}
	return null;
};
